import React from 'react';
import PropTypes from 'prop-types';
import FilterList from './filterList';
import Filter from './filter';
import { Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { handleActiveFilter } from '../store/catalog';
import ActiveFilters from './activeFilters';
import { getProductsLoadingStatus } from '../store/products';
import { getProductCategoriesLoadingStatus } from '../store/productCategories';
import { getStylesLoadingStatus } from '../store/styles';
import { getTaresLoadingStatus } from '../store/tares';
import { getSizesLoadingStatus } from '../store/sizes';

const FiltersPanel = ({ categorySlug }) => {
  const dispatch = useDispatch();
  const isProductsLoading = useSelector(getProductsLoadingStatus());
  const filtersList = {
    alcohol: [
      {
        name: 'manufacturer',
        label: 'Производитель',
        isLoadingData: isProductsLoading,
      },
      {
        name: 'categories',
        label: 'Категория',
        isLoadingData: useSelector(getProductCategoriesLoadingStatus()),
      },
      {
        name: 'style',
        label: 'Стили',
        isLoadingData: useSelector(getStylesLoadingStatus()),
      },
      {
        name: 'tare',
        label: 'Тара',
        isLoadingData: useSelector(getTaresLoadingStatus()),
      },
      {
        name: 'size',
        label: 'Объем',
        isLoadingData: useSelector(getSizesLoadingStatus()),
      },
    ],
  };

  const handleChange = (value, filterName, checked) => () => {
    dispatch(handleActiveFilter(value, filterName, checked));
  };
  return (
    <Paper variant="filterPanel" sx={{ width: 250 }}>
      <h5>Фильтр</h5>
      {!isProductsLoading && <ActiveFilters />}
      <FilterList>
        {filtersList[categorySlug] &&
          filtersList[categorySlug].map((filter, index) =>
            !filter.isLoadingData ? (
              <Filter
                {...filter}
                onChange={handleChange}
                key={index}
                index={index}
              />
            ) : (
              <React.Fragment key={index} />
            ),
          )}
      </FilterList>
    </Paper>
  );
};
FiltersPanel.defaultProps = {};
FiltersPanel.propTypes = {
  categorySlug: PropTypes.string,
  categories: PropTypes.array,
};
export default React.memo(FiltersPanel);
