import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  getProductCategoriesLoadingStatus,
  getCategoriesTree,
} from './store/productCategories';
import { getStylesLoadingStatus } from './store/styles';
import { getTaresLoadingStatus } from './store/tares';
import { getSizesLoadingStatus } from './store/sizes';
import { useTheme } from '@mui/material/styles';
import { Drawer, Box, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import FilterSkeleton from './components/filterSkeleton';
import getCurrentCategory from './utils/getCurrentCategory';
import SortPanel from './components/sortPanel';
import ProductList from './components/productList';
import ViewPanel from './components/viewPanel';
import { getCatalogState } from './store/catalog';
import SmoothScroll from './components/smoothScroll';
import ProductFilter from './utils/productFilter';
import FiltersPanel from './components/filtersPanel';
import { loadManufacturersListByCategory } from './store/manufacturers';
import { setStorageCatalogState } from './service/localStorage.service';

function App() {
  const dispatch = useDispatch();
  const isLoadingCategory = useSelector(getProductCategoriesLoadingStatus());
  const isStyleLoading = useSelector(getStylesLoadingStatus());
  const isTareLoading = useSelector(getTaresLoadingStatus());
  const isSizeLoading = useSelector(getSizesLoadingStatus());
  const isFilterDataLoaded =
    !isLoadingCategory & !isStyleLoading & !isTareLoading & !isSizeLoading;

  //
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const catalogState = useSelector(getCatalogState());
  const { filter: activeFilters } = catalogState;

  useEffect(() => {
    setStorageCatalogState(catalogState);
  }, [catalogState]);
  // Определение категории
  const { categorySlug, category } = getCurrentCategory();
  const categories = useSelector(getCategoriesTree(category.id))?.list?.ids;

  useEffect(() => {
    dispatch(loadManufacturersListByCategory(categories));
  }, []);

  //

  //
  const pageSize = 50;
  useEffect(() => {
    setCurrentPage(1);
  }, [activeFilters]);

  const fetchMoreData = (productList) => {
    setCurrentPage(currentPage + 1);
    setHasMore((currentPage + 1) * pageSize < productList.length);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const bps = {
    xs: useMediaQuery(theme.breakpoints.down('sm')),
    sm: useMediaQuery(theme.breakpoints.down('md')),
    md: useMediaQuery(theme.breakpoints.down('lg')),
    lg: useMediaQuery(theme.breakpoints.down('xl')),
    xl: useMediaQuery(theme.breakpoints.up('xl')),
  };

  const bp = _.findKey(bps, function (o) {
    return o;
  });

  // Drawer
  const [state, setState] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(open);
  };

  const productsByCategory = new ProductFilter(categories);
  productsByCategory.filter().search().sort();
  const filteredProducts = productsByCategory.products;

  const productListCrop = filteredProducts.slice(0, currentPage * pageSize);
  return (
    <>
      <Drawer anchor={'left'} open={state} onClose={toggleDrawer(false)}>
        {matches && isFilterDataLoaded && (
          <FiltersPanel categorySlug={categorySlug} categories={categories} />
        )}
      </Drawer>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {!matches && (
            <Grid xs="auto">
              {isFilterDataLoaded ? (
                <FiltersPanel
                  categorySlug={categorySlug}
                  categories={categories}
                />
              ) : (
                <FilterSkeleton />
              )}
            </Grid>
          )}
          <Grid xs>
            <SortPanel toggleDrawer={toggleDrawer} matches={matches} />
            <ViewPanel />
            <ProductList
              pageSize={pageSize}
              fetchMoreData={fetchMoreData}
              hasMore={hasMore}
              productList={filteredProducts}
              productListCrop={productListCrop}
              categorySlug={categorySlug}
              bp={bp}
            />
          </Grid>
        </Grid>
        <SmoothScroll hidden={currentPage === 1} />
      </Box>
    </>
  );
}

export default App;
