import { createSlice } from '@reduxjs/toolkit';
import siteOptionsService from '../service/site.options.service';

const siteOptionsSlice = createSlice({
  name: 'siteOptions',
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
  },
  reducers: {
    siteOptionsRequested: (state) => {
      state.isLoading = true;
    },
    // siteOptionsReceived: (state, action) => {
    //   state.entities = {};
    //   state.entities = action.payload;
    //   state.isLoading = false;
    // },
    siteOptionsReceived: (state, action) => {
      state.entities = {};
      state.entities = action.payload.reduce((sum, o) => {
        sum[o.name] = o.value;
        return sum;
      }, {});
      state.isLoading = false;
    },
    siteOptionsRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { reducer: siteOptionsReducer, actions } = siteOptionsSlice;
const { siteOptionsRequested, siteOptionsReceived, siteOptionsRequestFailed } =
  actions;

export const loadSiteOptionsList =
  (item = null, params = {}) =>
  async (dispatch) => {
    dispatch(siteOptionsRequested());
    try {
      const { result } = await siteOptionsService.fetchAll(params);
      dispatch(siteOptionsReceived(result));
    } catch (e) {
      dispatch(siteOptionsRequestFailed(e.message));
    }
  };

export const getSiteOptions = () => (state) => state.siteOptions.entities;
export const getSiteOptionsStatus = () => (state) =>
  state.siteOptions.isLoading;

export default siteOptionsReducer;
