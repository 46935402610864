import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: { value: false },
  reducers: {
    toggle: (state) => {
      state.value = !state.value;
    },
    set: (state, action) => {
      state.value = action.payload;
    },
  },
});

const { reducer: modalReducer, actions } = modalSlice;
const { toggle, set } = actions;

export const toggleModal = () => (dispatch) => {
  dispatch(toggle());
};
export const setModal = (value) => (dispatch) => {
  dispatch(set(value));
};

export const getModalState = () => (state) => state.modal.value;

export default modalReducer;
