import { createSlice } from '@reduxjs/toolkit';
import productAttributeService from '../service/product.attribute.service';

const sizesSlice = createSlice({
  name: 'sizes',
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
  },
  reducers: {
    sizesRequested: (state) => {
      state.isLoading = true;
    },
    sizesReceived: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
    },
    sizesRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { reducer: sizesReducer, actions } = sizesSlice;
const { sizesRequested, sizesReceived, sizesRequestFailed } = actions;

export const loadSizesList = () => async (dispatch) => {
  dispatch(sizesRequested());
  try {
    const { result } = await productAttributeService.fetchAll({
      name: 'size',
      format: 'assoc',
    });
    dispatch(sizesReceived(result));
  } catch (e) {
    dispatch(sizesRequestFailed(e.message));
  }
};

export const getSizes = () => (state) => state.sizes.entities;
export const getSizesLoadingStatus = () => (state) => state.sizes.isLoading;

export const getSizesSelectFilter = () => (state) => {
  const sizes = state.sizes.entities;
  if (sizes) {
    return Object.keys(sizes).map((id) => ({
      label: sizes[id],
      value: id,
    }));
  }
};

export const getSizesSelectOptions = () => (state) => {
  if (state.sizes.entities) {
    return state.sizes.entities.map(({ title: label, id: value }) => ({
      label,
      value: label,
    }));
  }
};

export default sizesReducer;
