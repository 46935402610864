import { createSlice } from '@reduxjs/toolkit';
import productAttributeService from '../service/product.attribute.service';
import _ from 'lodash';

const stylesSlice = createSlice({
  name: 'styles',
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
  },
  reducers: {
    stylesRequested: (state) => {
      state.isLoading = true;
    },
    stylesReceived: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
    },
    stylesRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { reducer: stylesReducer, actions } = stylesSlice;
const { stylesRequested, stylesReceived, stylesRequestFailed } = actions;

export const loadStylesList = () => async (dispatch) => {
  dispatch(stylesRequested());
  try {
    const { result } = await productAttributeService.fetchAll({
      name: 'style',
      format: 'assoc',
    });
    dispatch(stylesReceived(result));
  } catch (e) {
    dispatch(stylesRequestFailed(e.message));
  }
};

export const getStyles = () => (state) => state.styles.entities;

export const getStyleById = (id) => (state) => state.styles.entities[id];

export const getStylesLoadingStatus = () => (state) => state.styles.isLoading;

export const getStylesSelectFilter = () => (state) => {
  const styles = state.styles.entities;
  if (styles) {
    const array = Object.keys(styles).map((id) => ({
      label: styles[id],
      value: id,
    }));
    return _.sortBy(array, [
      function (o) {
        return o.label;
      },
    ]);
  }
};

export const getStylesSelectOptions = () => (state) => {
  if (state.styles.entities) {
    return state.styles.entities.map(({ title: label, id: title }) => ({
      label,
      value: label,
    }));
  }
};

export default stylesReducer;
