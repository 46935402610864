import { createSlice } from '@reduxjs/toolkit';
import productManufacturerService from '../service/product.manufacturer.service';

const manufacturersSlice = createSlice({
  name: 'manufacturers',
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
    byCategories: {
      entities: null,
      isLoading: true,
      error: null,
    },
  },
  reducers: {
    manufacturersRequested: (state) => {
      state.isLoading = true;
    },
    manufacturersReceived: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
    },
    manufacturersRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    manufacturersByCategoryRequested: (state) => {
      state.byCategories.isLoading = true;
    },
    manufacturersByCategoryReceived: (state, action) => {
      state.byCategories.entities = action.payload;
      state.byCategories.isLoading = false;
    },
    manufacturersByCategoryRequestFailed: (state, action) => {
      state.byCategories.error = action.payload;
      state.byCategories.isLoading = false;
    },
  },
});

const { reducer: manufacturersReducer, actions } = manufacturersSlice;
const {
  manufacturersRequested,
  manufacturersReceived,
  manufacturersFailed,
  manufacturersByCategoryRequested,
  manufacturersByCategoryReceived,
  manufacturersByCategoryFailed,
} = actions;

export const loadManufacturersList = () => async (dispatch) => {
  dispatch(manufacturersRequested());
  try {
    const { result } = await productManufacturerService.fetchAll({
      format: 'assoc',
    });
    dispatch(manufacturersReceived(result));
  } catch (e) {
    dispatch(manufacturersFailed(e.message));
  }
};

export const loadManufacturersListByCategory =
  (categories) => async (dispatch) => {
    dispatch(manufacturersByCategoryRequested());
    try {
      const { result } = await productManufacturerService.fetchAll({
        format: 'filter',
        categories,
      });
      dispatch(manufacturersByCategoryReceived(result));
    } catch (e) {
      dispatch(manufacturersByCategoryFailed(e.message));
    }
  };

export const getManufacturers = () => (state) => state.manufacturers.entities;
export const getManufacturersLoadingStatus = () => (state) =>
  state.manufacturers.isLoading;

export const getManufacturersByCategory = () => (state) =>
  state.manufacturers.byCategories.entities;
export const getManufacturersByCategoryLoadingStatus = () => (state) =>
  state.manufacturers.byCategories.isLoading;

export const getManufacturersSelectFilter = () => (state) => {
  const manufacturers = state.manufacturers.entities;
  if (manufacturers) {
    return Object.keys(manufacturers).map((id) => ({
      label: manufacturers[id],
      value: id,
    }));
  }
};

export default manufacturersReducer;
