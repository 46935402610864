import React from 'react';
import PropTypes from 'prop-types';
import { Button, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Grid from '@mui/material/Unstable_Grid2';
import SortSelect from './sortSelect';
import { getCatalogState, toggleListMode } from '../store/catalog';
import { productListModes } from '../configs';
import { useDispatch, useSelector } from 'react-redux';

const SortPanel = ({ toggleDrawer, matches }) => {
  const dispatch = useDispatch();
  const { listMode } = useSelector(getCatalogState());
  return (
    <>
      <Paper
        variant="filterPanel"
        sx={{ paddingBottom: 0, marginBottom: '1rem' }}
      >
        <Grid
          container
          sx={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          spacing={4}
        >
          <Grid xs="auto" hidden={!matches}>
            <Button startIcon={<FilterAltIcon />} onClick={toggleDrawer(true)}>
              Фильтр
            </Button>
          </Grid>
          <Grid xs display="flex" justifyContent="left">
            <ToggleButtonGroup
              size="small"
              value={listMode}
              onChange={(e, mode) => dispatch(toggleListMode(mode))}
              exclusive
              aria-label="Small sizes"
            >
              {Object.keys(productListModes).map((mode) => (
                <ToggleButton value={mode} key={mode}>
                  {productListModes[mode].icon}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
          <Grid md={6} xs={12}>
            <SortSelect />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
SortPanel.defaultProps = {
  prop: 'Stranger',
};
SortPanel.propTypes = {
  toggleDrawer: PropTypes.func,
  matches: PropTypes.bool,
  handleSortChange: PropTypes.func,
  currentSortValue: PropTypes.string,
  handleSearchChange: PropTypes.func,
};
export default React.memo(SortPanel);
