import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogState, handleSearch } from '../store/catalog';

const SearchInput = () => {
  const dispatch = useDispatch();
  const { search: value } = useSelector(getCatalogState());
  const handleChange = ({ target }) => {
    dispatch(handleSearch(target.value));
  };
  const theme = useTheme();
  return (
    <TextField
      sx={{
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.typography.gray10,
        },
      }}
      onChange={handleChange}
      size="small"
      id="outlined-basic"
      variant="outlined"
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
SearchInput.defaultProps = {};
SearchInput.propTypes = {
  prop: PropTypes.string,
};
export default SearchInput;
