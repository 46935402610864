import { combineReducers, configureStore } from '@reduxjs/toolkit';
import productsReducer from './products';
import productCategoriesReducer from './productCategories';
import sizesReducer from './sizes';
import taresReducer from './tares';
import stylesReducer from './styles';
import manufacturersReducer from './manufacturers';
import siteOptionsReducer from './siteOptions';
// import htmlSectionsReducer from './htmlSections';
import modalReducer from './modal';
import catalogReducer from './catalog';
// import currentUserReducer from './currentUser';
// import feedbackReducer from './feedback';

const rootReducer = combineReducers({
  products: productsReducer,
  productCategories: productCategoriesReducer,
  sizes: sizesReducer,
  tares: taresReducer,
  styles: stylesReducer,
  manufacturers: manufacturersReducer,
  siteOptions: siteOptionsReducer,
  modal: modalReducer,
  catalog: catalogReducer,
  // htmlSections: htmlSectionsReducer,
  // currentUser: currentUserReducer,
  // modals: modalsReducer,
  // feedback: feedbackReducer,
});

export function createStore() {
  return configureStore({
    reducer: rootReducer,
  });
}
