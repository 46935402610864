import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getManufacturers } from '../store/manufacturers';
import { getStyleById } from '../store/styles';
import { getSiteOptions } from '../store/siteOptions';
import { getTares } from '../store/tares';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';

import { productListModes } from '../configs';
import Price from './price';
import { getCatalogState } from '../store/catalog';
import Chip from '@mui/material/Chip';

const EComCard = ({ product, categorySlug }) => {
  const theme = useTheme();
  const { listMode } = useSelector(getCatalogState());
  const { label, article, title, price, manufacturerId, stockStatus, pack } =
    product;
  const {
    style: styleId,
    tare: tareId,
    ratingCount,
    ratingScore,
  } = product.extraInfo;

  const manufacturer = useSelector(getManufacturers())[manufacturerId];
  const style = useSelector(getStyleById(styleId));
  const tare = useSelector(getTares())[tareId];
  const { 'gallery.rootPath': rootPath } = useSelector(getSiteOptions());
  const productLink = `/catalog/${categorySlug}/${product.slug}`;

  return (
    <Grid xs={2}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'start',
          fontSize: productListModes[listMode].fontSize,
          '.MuiTypography-body1': {
            fontSize: productListModes[listMode].fontSize,
          },
          backgroundColor: parseInt(stockStatus) !== 2 ? null : '#212330',
        }}
      >
        <CardActionArea
          component="a"
          href={productLink}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'start',
          }}
        >
          <Box
            sx={{
              width: '100%',
              position: 'relative',
              ':before': {
                display: 'block',
                paddingTop: '100%',
                content: `""`,
              },
            }}
          >
            <CardMedia
              component="img"
              image={`/${rootPath}${label}`}
              alt={title}
              sx={{ position: 'absolute', top: 0, left: 0, height: '100%' }}
            />
            <Chip
              color="warning"
              size="small"
              label="New"
              sx={{ position: 'absolute', top: 5, right: 5 }}
              hidden={product.new !== '1'}
            />
          </Box>
          <CardContent
            sx={{
              padding: '1em',
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography gutterBottom color={theme.palette.text.secondary}>
                {manufacturer}
              </Typography>
              <Typography sx={{ wordBreak: 'break-all' }}>{article}</Typography>
            </Box>

            <Box
              sx={{
                marginTop: '1em',
              }}
            >
              <Typography gutterBottom color={theme.palette.text.secondary}>
                {style}
              </Typography>
              <Typography>
                {ratingScore && Number(ratingScore) > 0 ? (
                  <>
                    <span className="color-untappd">
                      <i className="fab fa-untappd me-1" />
                      {parseFloat(ratingScore).toFixed(1)}
                    </span>
                    <span className="text-muted ms-1">({ratingCount})</span>
                  </>
                ) : (
                  <span>&nbsp;</span>
                )}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        <CardActions
          sx={{
            alignItems: 'flex-start',
            flexDirection: 'column',
            padding: '0.8em 1em',
          }}
        >
          <Price
            price={price}
            pack={pack}
            tare={tare}
            stockStatus={stockStatus}
          />
        </CardActions>
      </Card>
    </Grid>
  );
};
EComCard.propTypes = {
  product: PropTypes.object,
  categorySlug: PropTypes.string,
};

export default EComCard;
