import React from 'react';
import { Skeleton, Box } from '@mui/material';

const FilterSkeleton = () => {
  return (
    <Box sx={{ width: 180 }}>
      {[1, 2, 3].map((item, idx) => (
        <React.Fragment key={idx}>
          <Skeleton
            variant="text"
            sx={{ backgroundColor: 'grey.900', height: '48px' }}
          />
          {Array(10)
            .fill(1)
            .map((item, idx) => (
              <Skeleton
                key={idx}
                variant="text"
                sx={{
                  backgroundColor: 'grey.900',
                  marginRight: '30px',
                  height: '32px',
                }}
              />
            ))}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default React.memo(FilterSkeleton);
