import httpService from './http.service';

const productCategoryEndpoint = 'product-category';

const productCategoryService = {
  update: async (id, content) => {
    const { data } = await httpService.put(
      productCategoryEndpoint + id,
      content,
    );
    return data;
  },
  get: async (id) => {
    const { data } = await httpService.get(productCategoryEndpoint + id);
    return data;
  },
  fetchAll: async (params = {}) => {
    const { data } = await httpService.get(productCategoryEndpoint, { params });
    return data;
  },
  create: async (content) => {
    const { data } = await httpService.post(productCategoryEndpoint, content);
    return data;
  },
  delete: async (id) => {
    const { data } = await httpService.delete(productCategoryEndpoint + id);
    return data;
  },
};

export default productCategoryService;
