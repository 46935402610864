import _ from 'lodash';

export default function filter(products, filterList, config) {
  const data = { products, attrVariations: {} };
  Object.keys(filterList).forEach((field) => {
    if (config[field])
      Object.keys(config[field]).forEach(() => {
        data.products = data.products.filter((product) => {
          const value = _.at(product, config[field].valueField)[0];
          if (!_.isEmpty(filterList[field])) {
            if (filterList[field].includes(value)) {
              data.attrVariations = setVariations(
                product,
                config,
                data.attrVariations,
              );
              return true;
            }
            return false;
          }
          return true;
        });
      });
  });
  data.count = data.products.length;
  return data;
}

function setVariations(product, config, variations) {
  Object.keys(config).forEach((field) => {
    if (config[field]) {
      variations[field] = _.hasIn(variations, field)
        ? _.uniq([
            ...variations[field],
            _.at(product, config[field].valueField)[0],
          ])
        : [_.at(product, config[field].valueField)[0]];
    }
  });

  return variations;
}

// export default function filter(products, filterList, config) {
//   Object.keys(filterList).forEach((field) => {
//     if (config[field])
//       Object.keys(config[field]).forEach(() => {
//         products = products.filter((product) => {
//           return filterList[field]?.length > 0
//             ? filterList[field].includes(
//                 _.at(product, config[field].valueField)[0],
//               )
//             : products;
//         });
//       });
//   });
//   return products;
// }
