import React from 'react';
import { Box, Fab, useTheme } from '@mui/material';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import PropTypes from 'prop-types';

const SmoothScroll = ({ hidden }) => {
  const theme = useTheme();
  const smoothScroll = (e) => {
    e.preventDefault();

    window.scrollBy({
      top: -window.pageYOffset,
      behavior: 'smooth',
    });
  };
  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: 50,
        zIndex: 1030,
        display: 'flex',
        flexDirection: 'row-reverse',
        pr: '50px',
      }}
      hidden={hidden}
    >
      <Fab
        size="medium"
        color="primary"
        aria-label="KeyboardDoubleArrowUpIcon"
        onClick={smoothScroll}
      >
        <KeyboardDoubleArrowUpIcon color={theme.typography.gray10} />
      </Fab>
    </Box>
  );
};
SmoothScroll.propTypes = {
  hidden: PropTypes.bool,
};

export default React.memo(SmoothScroll);
