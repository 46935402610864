import history from './history';
import { useSelector } from 'react-redux';
import { getCategoryBySlug } from '../store/productCategories';

export default function getCurrentCategory() {
  const pathnameArray = history.location.pathname.split('/');
  const categorySlug = pathnameArray[pathnameArray.length - 1];
  const category = useSelector(getCategoryBySlug(categorySlug));
  return { categorySlug, category };
}
