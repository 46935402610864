import React from 'react';
import { Skeleton, Box, Card } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { productListModes } from '../configs';

const EComCardSkeleton = () => {
  return (
    <Grid md={2}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            mb: 2,
            ':before': {
              display: 'block',
              paddingTop: '100%',
              content: `""`,
            },
          }}
        >
          <Skeleton
            variant="rounded"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
            }}
          />
        </Box>

        <Skeleton
          variant="rectangular"
          sx={{ fontSize: '1rem', width: '100%', mb: 1 }}
        />
        <Skeleton
          variant="rectangular"
          sx={{ fontSize: '1rem', width: '100%', mb: 1 }}
        />
        <Skeleton
          variant="rectangular"
          sx={{ fontSize: '1rem', width: '100%', mb: 1 }}
        />
        <Skeleton
          variant="rectangular"
          sx={{ fontSize: '1rem', width: '100%', mb: 1 }}
        />
      </Box>
    </Grid>
  );
};

export default React.memo(EComCardSkeleton);
