import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogState, handleSort } from '../store/catalog';

const SortSelect = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { sort: value } = useSelector(getCatalogState());

  const handleChange = ({ target }) => {
    dispatch(handleSort(target.value));
  };

  const sortData = [
    {
      text: 'Новинки (по убыванию)',
      value: 'newAt|desc',
    },
    {
      text: 'Новинки (по возрастанию)',
      value: 'newAt|asc',
    },
    {
      text: 'По алфавиту (по убыванию)',
      value: 'article|desc',
    },
    {
      text: 'По алфавиту (по возрастанию)',
      value: 'article|asc',
    },
    {
      text: 'По цене (по убыванию)',
      value: 'price|desc',
    },
    {
      text: 'По цене (по возрастанию)',
      value: 'price|asc',
    },
  ];

  return (
    <FormControl sx={{ width: '100%' }} size="small">
      <Select
        // variant="standard"
        id="demo-select-small"
        value={value}
        onChange={handleChange}
        sx={{
          width: '100%',
          '& .MuiSelect-icon': {
            color: theme.typography.gray10,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.typography.gray10,
          },
        }}
      >
        {sortData.map(({ text, value }) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
SortSelect.defaultProps = {};
SortSelect.propTypes = {
  prop: PropTypes.string,
};
export default SortSelect;
