import httpService from './http.service';

const productAttributeEndpoint = 'attributes';

const productAttributeService = {
  get: async (name, id) => {
    const { data } = await httpService.get(
      productAttributeEndpoint + name + id,
    );
    return data;
  },
  fetchAll: async (params = {}) => {
    const { data } = await httpService.get(productAttributeEndpoint, {
      params,
    });
    return data;
  },
};

export default productAttributeService;
