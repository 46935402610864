import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { loadProductsList } from '../../store/products';
import { loadSizesList } from '../../store/sizes';
import { loadTaresList } from '../../store/tares';
import { loadStylesList } from '../../store/styles';
import {
  getProductCategoriesLoadingStatus,
  loadProductCategoriesList,
} from '../../store/productCategories';
import { loadManufacturersList } from '../../store/manufacturers';
import { loadSiteOptionsList } from '../../store/siteOptions';

const AppLoader = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProductCategoriesList());
    dispatch(loadProductsList());
    dispatch(loadSizesList());
    dispatch(loadTaresList());
    dispatch(loadStylesList());
    dispatch(loadManufacturersList());
    dispatch(loadSiteOptionsList());
  }, []);
  const isLoadingCategory = useSelector(getProductCategoriesLoadingStatus());
  return !isLoadingCategory && children;
};

AppLoader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AppLoader;
