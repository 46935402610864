import { useSelector } from 'react-redux';
import { getProductsByCategory } from '../store/products';
import filtrator from './filtrator';
import { getCatalogState } from '../store/catalog';
import _ from 'lodash';

class ProductFilter {
  constructor(categories) {
    this.products = useSelector(getProductsByCategory(categories));
    this.catalogState = useSelector(getCatalogState());
    this.filterConfig = {
      categories: { valueField: 'category_id' },
      tare: { valueField: 'extraInfo.tare' },
      size: { valueField: 'extraInfo.size' },
      style: { valueField: 'extraInfo.style' },
      manufacturer: { valueField: 'manufacturerId' },
    };
  }

  filter() {
    this.products =
      this.catalogState.filter !== {}
        ? filtrator(this.products, this.catalogState.filter, this.filterConfig)
            .products
        : this.products;
    return this;
  }

  search() {
    if (this.catalogState.search !== '') {
      this.products = this.products.filter((p) =>
        p.title.toLowerCase().includes(this.catalogState.search.toLowerCase()),
      );
    }
    return this;
  }

  sort() {
    if (/\w\|\w/.test(this.catalogState.sort)) {
      this.products = _.orderBy(
        this.products,
        [this.catalogState.sort?.split('|')[0]],
        [this.catalogState.sort?.split('|')[1]],
      );
    }
    this.products = _.orderBy(
      this.products,
      ['new', 'stockStatus'],
      ['desc', 'asc'],
    );

    return this;
  }
}

export default ProductFilter;
