import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const Price = ({ price, pack, tare, stockStatus }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const packCount = pack && pack.match(/([0-9]{2})[хл]/i);
  // const priceUnit = tare && tare === 'пэт кег' ? 'л' : 'шт';

  const priceUnit =
    tare && tare === 'пэт кег' ? (pack.includes('шт') ? 'шт' : 'л') : 'шт';

  return (
    <>
      {parseInt(stockStatus) !== 2 ? (
        <Typography variant="price">
          {`${price} руб./${priceUnit} `}
          <Button
            aria-describedby={id}
            onClick={handleClick}
            sx={{
              padding: 0,
              minWidth: '1em',
              paddingBottom: '.5em',
            }}
          >
            <InfoIcon sx={{ fontSize: 'inherit' }} />
          </Button>
        </Typography>
      ) : (
        <Typography color="error">Нет в наличии</Typography>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <TableContainer component={Paper} sx={{ fontSize: 13, p: '1em' }}>
          <Table
            sx={{ '&>*, .MuiTableCell-root': { fontSize: '1em' } }}
            aria-label="simple table"
          >
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {tare}
                </TableCell>
                <TableCell align="right">{`${price} руб./${priceUnit}`}</TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {pack}
                </TableCell>
                <TableCell align="right">{`${
                  tare === 'пэт кег' && pack.includes('шт')
                    ? price
                    : (packCount ? packCount[1] : 0) * price
                } руб./шт`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Popover>
    </>
  );
};
Price.defaultProps = {};
Price.propTypes = {
  pack: PropTypes.string,
  tare: PropTypes.string,
  stockStatus: PropTypes.string,
  price: PropTypes.string,
};
export default Price;
