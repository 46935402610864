import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { getCatalogState } from '../store/catalog';
const Filter = ({ label, index, name, variations, onChange }) => {
  const catalogState = useSelector(getCatalogState());
  const selectedData = catalogState.filter[name] ?? [];
  const theme = useTheme();
  const [expanded, setExpanded] = useState('manufacturer');

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Accordion
      expanded={expanded === name}
      onChange={handleChangeAccordion(name)}
      variant="panel"
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        variant="panel"
        expandIcon={<ExpandMoreIcon sx={{ color: theme.typography.gray10 }} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          '&.Mui-expanded': {
            minHeight: '48px',
          },
          '.MuiAccordionSummary-content.Mui-expanded': {
            m: '12px 0',
          },
        }}
      >
        <Typography sx={{ flexShrink: 0, marginRight: '5px' }}>
          {label}
        </Typography>
        <Typography>{`(${variations.length})`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {variations.map(({ label, value, relation }, idx) => {
            const labelId = `${name}Variant${idx}`;
            const checked = relation
              ? selectedData.length - relation.length ===
                _.difference(selectedData, relation).length
              : selectedData.indexOf(value) !== -1;
            return (
              <ListItem key={idx} disablePadding dense>
                <ListItemButton
                  variant="filterItem"
                  role={undefined}
                  onClick={onChange(
                    relation ? [value, ...relation].join(',') : value,
                    name,
                    checked,
                  )}
                  dense
                >
                  <ListItemIcon variant="filterItem">
                    <Checkbox
                      sx={{ p: '4px 9px' }}
                      edge="start"
                      name={`filters[${name}][]`}
                      checked={checked}
                      // value={relation ? relation.join(',') : value} // value={relation ? '' : value}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': labelId,
                        'data-relation': relation,
                        'data-filter-name': name,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={label} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
Filter.defaultProps = {};
Filter.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  variations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  index: PropTypes.number,
  handleChangeAccordion: PropTypes.func,
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
};
export default React.memo(Filter);
