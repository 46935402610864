import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { createStore } from './store/createStore';
import './index.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import themeOptions from './utils/themeOptions';
import AppLoader from './components/hoc/appLoader';
import { ToastContainer } from 'react-toastify';

const theme = createTheme(themeOptions);
const store = createStore();
const root = ReactDOM.createRoot(document.getElementById('rootCatalog'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppLoader>
          <App />
        </AppLoader>
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);
