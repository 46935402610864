const themeOptions = {
  palette: {
    type: 'dark',
    primary: {
      main: '#696c80',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#0b0b0f',
      paper: '#12131a',
    },
    text: {
      primary: 'rgba(235,236,242,1)',
      secondary: 'rgba(235,236,242,0.7)',
    },
    divider: 'rgba(105,108,128,0.5)',
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 4,
  typography: {
    fontFamily: 'Roboto Slab, sans-serif',
    fontSize: 14,
    gray10: '#ebecf2',
    untappd: '#ffc000',
  },
  components: {
    MuiSvgIcon: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'filterPanel' },
          style: {
            minWidth: 240,
            padding: '1rem',
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'price' },
          style: {
            fontSize: '1.2em',
          },
        },
      ],
    },
    MuiCheckbox: {
      variants: [
        {
          props: { variant: 'filterItem' },
          style: {
            marginLeft: 0,
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      ],
    },
    MuiListItemButton: {
      variants: [
        {
          props: { variant: 'filterItem' },
          style: {
            padding: 0,
          },
        },
      ],
    },
    MuiListItemIcon: {
      variants: [
        {
          props: { variant: 'filterItem' },
          style: {
            minWidth: 30,
            marginLeft: 0,
          },
        },
      ],
    },
  },
};

export default themeOptions;
// https://bareynol.github.io/mui-theme-creator/#Icon
