import { createSlice } from '@reduxjs/toolkit';
import productCategoryService from '../service/product.category.service';

const productCategoriesSlice = createSlice({
  name: 'productCategories',
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
  },
  reducers: {
    productCategoriesRequested: (state) => {
      state.isLoading = true;
    },
    productCategoriesReceived: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
    },
    productCategoriesRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { reducer: productCategoriesReducer, actions } = productCategoriesSlice;
const {
  productCategoriesRequested,
  productCategoriesReceived,
  productCategoriesRequestFailed,
} = actions;

export const loadProductCategoriesList = (params) => async (dispatch) => {
  dispatch(productCategoriesRequested());
  try {
    const { result } = await productCategoryService.fetchAll(params);
    dispatch(productCategoriesReceived(result));
  } catch (e) {
    dispatch(productCategoriesRequestFailed(e.message));
  }
};

export const getProductCategories = () => (state) =>
  state.productCategories.entities;
export const getProductCategoriesLoadingStatus = () => (state) =>
  state.productCategories.isLoading;

export const getMenuList = () => (state) => {
  if (state.productCategories.entities) {
    return state.productCategories.entities.filter(
      (item) => (item.parent_id === '0') & (item.display === '1'),
    );
  } else {
    return [];
  }
};

export const getCategoryBySlug = (slug) => (state) => {
  if (state.productCategories.entities) {
    return state.productCategories.entities.find((c) => c.slug === slug);
  } else {
    return null;
  }
};

export const getCategoriesTree = (top) => (state) => {
  if (state.productCategories.entities) {
    const list = { ids: [top], objects: [] };
    const categories = state.productCategories.entities.filter(
      (item) => item.parent_id === top,
    );
    categories.forEach((category, index) => {
      categories[index] = {
        ...categories[index],
        sub: subCategories(category.id, list),
      };
      list.ids.push(category.id);
      list.objects.push(categories[index]);
    });

    function subCategories(id, list) {
      const categories = state.productCategories.entities.filter(
        (item) => item.parent_id === id,
      );

      categories.forEach((category, index) => {
        categories[index] = {
          ...categories[index],
          sub: subCategories(category.id, list),
        };
        list.ids.push(category.id);
        list.objects.push(categories[index]);
      });
      return categories;
    }
    return { list, tree: categories };
  } else {
    return [];
  }
};

export default productCategoriesReducer;
