import { createSlice } from '@reduxjs/toolkit';
import { getStorageCatalogState } from '../service/localStorage.service';
import configFile from '../config.json';
const storageCatalogState = getStorageCatalogState();

const catalogSlice = createSlice({
  name: 'catalog',
  initialState:
    storageCatalogState !== null &&
    storageCatalogState?.v === configFile.storageVersion
      ? storageCatalogState
      : {
          v: configFile.storageVersion,
          listMode: 'smGrid',
          search: '',
          sort: 'newAt|desc',
          filter: {},
          filtersData: {
            manufacturer: [],
          },
        },
  reducers: {
    setListMode: (state, action) => {
      state.listMode = action.payload;
    },
    setSearchValue: (state, action) => {
      state.search = action.payload;
    },
    setSortValue: (state, action) => {
      state.sort = action.payload;
    },
    setActiveFilter: (state, action) => {
      const { value, filterName, checked } = action.payload;
      console.log(value, filterName, checked);
      const values = value.split(',');
      const currentFilter = Object.keys(state.filter).includes(filterName)
        ? [...state.filter[filterName]]
        : [];

      let newFilter = currentFilter;
      // values.forEach((item) => {
      //   if (!checked) {
      //     newFilter = Array.from(new Set([...newFilter, item]));
      //   } else {
      //     const index = newFilter.indexOf(item);
      //     console.log(index);
      //     newFilter = newFilter.splice(index, 1);
      //   }
      // });
      if (!checked) {
        newFilter = Array.from(new Set([...newFilter, ...values]));
      } else {
        newFilter = newFilter.filter((item) => !values.includes(item));
      }
      state.filter[filterName] = newFilter;
    },
    clearActiveFilter: (state, action) => {
      state.filter = {};
    },
    setFiltersData: (state, action) => {
      const { name, data } = action.payload;
      state.filtersData[name] = data;
    },
  },
});

const { reducer: catalogReducer, actions } = catalogSlice;
const {
  setListMode,
  setSearchValue,
  setSortValue,
  setActiveFilter,
  clearActiveFilter,
} = actions;

export const getCatalogState = () => (state) => state.catalog;

export const toggleListMode = (value) => (dispatch) => {
  if (value !== null) {
    dispatch(setListMode(value));
  }
};
export const handleSearch = (value) => (dispatch) => {
  if (value !== null) {
    dispatch(setSearchValue(value));
  }
};
export const handleSort = (value) => (dispatch) => {
  dispatch(setSortValue(value));
};

export const handleActiveFilter =
  (value, filterName, checked) => (dispatch) => {
    dispatch(setActiveFilter({ value, filterName, checked }));
  };
export const handleClearActiveFilter = () => (dispatch) => {
  dispatch(clearActiveFilter());
};

export default catalogReducer;
