import React from 'react';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import AppsIcon from '@mui/icons-material/Apps';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';

export const productListModes = {
  smGrid: {
    icon: <ViewCompactIcon />,
    fontSize: 12,
    columns: { xs: 6, sm: 10, md: 6, lg: 14, xl: 14 },
  },
  lgGrid: {
    icon: <AppsIcon />,
    fontSize: 14,
    columns: { xs: 4, sm: 8, md: 6, lg: 10, xl: 10 },
  },
  lgList: {
    icon: <ViewStreamIcon />,
    avatar: 100,
  },
  smList: {
    icon: <ViewHeadlineIcon />,
    avatar: 80,
  },
};
