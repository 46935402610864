import { createSlice } from '@reduxjs/toolkit';
import productAttributeService from '../service/product.attribute.service';

const taresSlice = createSlice({
  name: 'tares',
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
  },
  reducers: {
    taresRequested: (state) => {
      state.isLoading = true;
    },
    taresReceived: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
    },
    taresRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { reducer: taresReducer, actions } = taresSlice;
const { taresRequested, taresReceived, taresRequestFailed } = actions;

export const loadTaresList = () => async (dispatch) => {
  dispatch(taresRequested());
  try {
    const { result } = await productAttributeService.fetchAll({
      name: 'tare',
      format: 'assoc',
    });
    dispatch(taresReceived(result));
  } catch (e) {
    dispatch(taresRequestFailed(e.message));
  }
};

export const getTares = () => (state) => state.tares.entities;
export const getTaresLoadingStatus = () => (state) => state.tares.isLoading;

export const getTaresSelectFilter = () => (state) => {
  const tares = state.tares.entities;
  if (tares) {
    return Object.keys(tares).map((id) => ({
      label: tares[id],
      value: id,
    }));
  }
};

export const getTaresSelectOptions = () => (state) => {
  if (state.tares.entities) {
    return state.tares.entities.map(({ title: label, id: value }) => ({
      label,
      value: label,
    }));
  }
};

export default taresReducer;
