import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider,
  ListItemButton,
  CardMedia,
  Box,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getManufacturers } from '../store/manufacturers';
import { getStyles } from '../store/styles';
import { getSizes } from '../store/sizes';
import { getTares } from '../store/tares';
import { getSiteOptions } from '../store/siteOptions';
import { useTheme } from '@mui/material/styles';
import { productListModes } from '../configs';
import Price from './price';
import { getCatalogState } from '../store/catalog';
import Chip from '@mui/material/Chip';

const EComListItem = ({ product, categorySlug }) => {
  const theme = useTheme();
  const { listMode } = useSelector(getCatalogState());
  const { label, article, title, price, manufacturerId, stockStatus, pack } =
    product;
  const {
    size: sizeId,
    style: styleId,
    tare: tareId,
    ratingCount,
    ratingScore,
  } = product.extraInfo;

  const manufacturer = useSelector(getManufacturers())[manufacturerId];
  const style = useSelector(getStyles())[styleId];
  const size = useSelector(getSizes())[sizeId];
  const tare = useSelector(getTares())[tareId];
  const { 'gallery.rootPath': rootPath, baseUrl } = useSelector(
    getSiteOptions(),
  );
  const productLink = `/catalog/${categorySlug}/${product.slug}`;
  const productLabel = `${baseUrl}/${rootPath}/${label}`;

  const itemText = {
    lgList: {
      primary: (
        <>
          <Typography color={theme.palette.text.secondary}>
            {manufacturer}
          </Typography>
          <Typography>{article}</Typography>
          <Typography color={theme.palette.text.secondary}>{style}</Typography>
          <Typography>
            {ratingScore && Number(ratingScore) > 0 ? (
              <>
                <span className="color-untappd">
                  <i className="fab fa-untappd me-1" />
                  {parseFloat(ratingScore).toFixed(1)}
                </span>
                <span className="text-muted ms-1">({ratingCount})</span>
              </>
            ) : (
              <span>&nbsp;</span>
            )}
          </Typography>
        </>
      ),
    },
    smList: {
      primary: title,
    },
  };
  return (
    <>
      <ListItem
        alignItems="center"
        sx={{
          bgcolor: 'background.paper',
          fontSize: 15,
          '& .MuiTypography-root': { fontSize: 'inherit' },
        }}
      >
        <ListItemButton
          disableGutters
          href={productLink}
          sx={{
            py: 0,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
            },
          }}
        >
          <ListItemAvatar>
            <Box
              sx={{
                width: productListModes[listMode].avatar,
                height: productListModes[listMode].avatar,
                position: 'relative',
                ':before': {
                  display: 'block',
                  paddingTop: '100%',
                  content: `""`,
                },
              }}
            >
              <CardMedia
                component="img"
                image={productLabel}
                alt={title}
                sx={{ position: 'absolute', top: 0, left: 0, height: '100%' }}
              />
              <Chip
                color="warning"
                size="small"
                label="New"
                sx={{ position: 'absolute', top: 5, right: 5 }}
                hidden={product.new !== '1'}
              />
            </Box>
          </ListItemAvatar>
          <ListItemText
            primary={itemText[listMode].primary}
            sx={{
              marginLeft: 3,
              '.MuiTypography-body1': {},
            }}
          />
        </ListItemButton>
        <ListItemText
          primary=<Price
            price={price}
            pack={pack}
            tare={tare}
            stockStatus={stockStatus}
          />
          sx={{ maxWidth: 120, textAlign: 'right' }}
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};
EComListItem.defaultProps = {
  prop: 'Stranger',
};
EComListItem.propTypes = {
  product: PropTypes.object,
  categorySlug: PropTypes.string,
};
export default React.memo(EComListItem);
