const productCategoriesConvertForFilter = (tree) => {
  return tree.list.objects.map(({ title: label, id: value, sub }) => {
    if (sub.length === 0) {
      return { label, value };
    } else {
      const relation = sub.map((item) => item.id);
      return { label, value, relation };
    }
  });
};

export default productCategoriesConvertForFilter;
