import httpService from './http.service';

const productEndpoint = 'product';

const productService = {
  update: async (id, content) => {
    const { data } = await httpService.put(productEndpoint + id, content);
    return data;
  },
  get: async (id) => {
    const { data } = await httpService.get(productEndpoint + id);
    return data;
  },
  fetchAll: async (params = {}) => {
    const { data } = await httpService.get(productEndpoint, {
      params,
    });
    return data;
  },
  create: async (content) => {
    const { data } = await httpService.post(productEndpoint, content);
    return data;
  },
  delete: async (id) => {
    const { data } = await httpService.delete(productEndpoint + id);
    return data;
  },
};

export default productService;
