import httpService from './http.service';

const siteOptionsEndpoint = 'site-options';

const siteOptionsService = {
  get: async (id) => {
    const { data } = await httpService.get(siteOptionsEndpoint + id);
    return data;
  },
  fetchAll: async (params = {}) => {
    const { data } = await httpService.get(siteOptionsEndpoint, { params });
    return data;
  },
};

export default siteOptionsService;
