import { createSlice } from '@reduxjs/toolkit';
import productService from '../service/product.service';
// import _ from 'lodash';

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    entities: null,
    new: null,
    isLoading: { all: true, new: true },
    error: null,
  },
  reducers: {
    productsRequested: (state, action) => {
      state.isLoading[action.payload] = true;
    },
    productsReceived: (state, action) => {
      state.entities = action.payload;
      state.isLoading.all = false;
    },
    newProductsReceived: (state, action) => {
      state.new = action.payload;
      state.isLoading.new = false;
    },
    productsRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { reducer: productsReducer, actions } = productsSlice;
const {
  productsRequested,
  productsReceived,
  productsRequestFailed,
  newProductsReceived,
} = actions;

export const loadProductsList = () => async (dispatch) => {
  dispatch(productsRequested('all'));
  try {
    const { result } = await productService.fetchAll({
      'where[stock_status_id]': 1,
      format: 'card',
    });
    dispatch(productsReceived(result));
  } catch (e) {
    dispatch(productsRequestFailed(e.message));
  }
};

export const loadNewProductsList = () => async (dispatch) => {
  dispatch(productsRequested('new'));
  try {
    const { result } = await productService.fetchAll({
      'where[new]': 1,
      'where[stock_status_id]': 1,
      whereIn: 'category_id|13,14,15,16,17,18,19',
      limit: 8,
      orderBy: 'created_at|DESC',
    });
    dispatch(newProductsReceived(result));
  } catch (e) {
    dispatch(productsRequestFailed(e.message));
  }
};

export const getProducts = () => (state) => {
  if (!state.products.isLoading.all) {
    return state.products.entities;
  }
  return [];
};
export const getNewProducts = () => (state) => state.products.new;
export const getProductsLoadingStatus = () => (state) =>
  state.products.isLoading.all;
export const getNewProductsLoadingStatus = () => (state) =>
  state.products.isLoading.new;

// export const getNewProducts =
//   (limit = 8, orderBy = 'createdAt') =>
//   (state) => {
//     if (state.products.entities) {
//       let list = state.products.entities.filter(
//         (product) =>
//           product.new === '1' &&
//           ['13', '15', '17', '19'].includes(product.category_id),
//       );
//       if (orderBy) list = _.orderBy(list, ['createdAt'], ['desc']); // if (orderBy) list = _.sortBy(list, [(o) => o.create_time]);
//       if (limit) list = _.slice(list, 0, limit);
//
//       return list;
//     } else {
//       return [];
//     }
//   };
export const getProductsByCategory = (categories) => (state) => {
  const rows = state.products.entities;
  if (rows) {
    return rows.filter((product) => categories.includes(product.category_id));
  } else {
    return [];
  }
};
export const getManufacturerByCategory = (categories) => (state) => {
  const rows = state.products.entities;
  if (rows) {
    return new Set(
      rows
        .filter(
          (product) =>
            categories.includes(product.category_id) &
            (Number(product.stockStatus) === 1),
        )
        .map((product) => product.manufacturerId),
    );
  } else {
    return [];
  }
};
export const getProductBySlug = (slug) => (state) => {
  if (state.products.entities) {
    return state.products.entities.find((p) => p.slug === slug);
  } else {
    return null;
  }
};

export default productsReducer;
