import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import EComCard from './ecomCard';
import { useSelector } from 'react-redux';
import { getSiteOptionsStatus } from '../store/siteOptions';
import Grid from '@mui/material/Unstable_Grid2';
import { productListModes } from '../configs';
import { List } from '@mui/material';
import EComListItem from './ecomListItem';
import { getCatalogState } from '../store/catalog';
import { getProductsLoadingStatus } from '../store/products';
import EComCardSkeleton from './ecomCardSkeleton';

const ProductList = ({
  productListCrop,
  fetchMoreData,
  productList,
  hasMore,
  categorySlug,
  pageSize,
  bp,
}) => {
  const isProductsLoading = useSelector(getProductsLoadingStatus());
  const { listMode } = useSelector(getCatalogState());
  const siteOptionsIsLoading = useSelector(getSiteOptionsStatus());
  const isGridMode = listMode === 'smGrid' || listMode === 'lgGrid';
  const isListMode = listMode === 'smList' || listMode === 'lgList';

  return (
    <>
      {!isProductsLoading ? (
        productListCrop.length > 0 ? (
          <InfiniteScroll
            className="sortList__item item_productList"
            dataLength={productListCrop.length}
            next={() => fetchMoreData(productList)}
            hasMore={hasMore}
            loader={<div>Загрузка...</div>}
            endMessage={null}
          >
            {isGridMode && (
              <Grid
                container
                spacing={2}
                sx={{
                  m: 0,
                  p: 0,
                }}
                columns={
                  isGridMode ? productListModes[listMode].columns[bp] : 1
                }
              >
                {!siteOptionsIsLoading &&
                  productListCrop.map((item) => (
                    <EComCard
                      mode={listMode}
                      key={item.id}
                      product={item}
                      categorySlug={categorySlug}
                    />
                  ))}
              </Grid>
            )}
            {isListMode && (
              <List sx={{ width: '100%' }}>
                {!siteOptionsIsLoading &&
                  productListCrop.map((item, idx) => (
                    <EComListItem
                      key={idx}
                      product={item}
                      categorySlug={categorySlug}
                    />
                  ))}
              </List>
            )}
          </InfiniteScroll>
        ) : (
          <div>Ничего не найдено</div>
        )
      ) : (
        <Grid
          container
          spacing={2}
          sx={{
            m: 0,
            p: 0,
          }}
          columns={isGridMode ? productListModes[listMode].columns[bp] : 1}
        >
          {Array(pageSize)
            .fill(0)
            .map((item, idx) => (
              <EComCardSkeleton key={idx} />
            ))}
        </Grid>
      )}
    </>
  );
};
ProductList.defaultProps = {
  bp: 'lg',
};
ProductList.propTypes = {
  pageSize: PropTypes.number,
  productListCrop: PropTypes.array,
  productList: PropTypes.array,
  hasMore: PropTypes.bool,
  fetchMoreData: PropTypes.func,
  categorySlug: PropTypes.string,
  bp: PropTypes.string,
};

export default ProductList;
