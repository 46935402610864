import React from 'react';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SearchInput from './seachInput';

const ViewPanel = () => {
  return (
    <>
      <Paper
        variant="filterPanel"
        sx={{ paddingBottom: 0, marginBottom: '1rem' }}
      >
        <Grid
          container
          sx={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          spacing={4}
        >
          <Grid xs>
            <SearchInput />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default React.memo(ViewPanel);
