import React from 'react';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import productCategoriesConvertForFilter from '../utils/productCategoriesConvertForFilter';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesTree } from '../store/productCategories';
import Grid2 from '@mui/material/Unstable_Grid2';
import getCurrentCategory from '../utils/getCurrentCategory';
import { getStylesSelectFilter } from '../store/styles';
import { getTaresSelectFilter } from '../store/tares';
import { getSizesSelectFilter } from '../store/sizes';
import {
  getCatalogState,
  handleActiveFilter,
  handleClearActiveFilter,
} from '../store/catalog';
import { getManufacturersSelectFilter } from '../store/manufacturers';

const ActiveFilters = () => {
  const dispatch = useDispatch();
  const { category } = getCurrentCategory();
  const categories = useSelector(getCategoriesTree(category.id));
  const catalogState = useSelector(getCatalogState());

  const clearFilter = () => {
    dispatch(handleClearActiveFilter());
  };

  const filtersOptions = {
    categories: {
      avatar: 'К',
      list: productCategoriesConvertForFilter(categories),
    },
    tare: {
      avatar: 'Т',
      list: useSelector(getTaresSelectFilter()),
    },
    size: {
      avatar: 'О',
      list: useSelector(getSizesSelectFilter()),
    },
    style: {
      avatar: 'С',
      list: useSelector(getStylesSelectFilter()),
    },
    manufacturer: {
      avatar: 'П',
      list: useSelector(getManufacturersSelectFilter()),
    },
  };

  const handleChange = (value, filterName, checked) => () => {
    dispatch(handleActiveFilter(value, filterName, checked));
  };
  const isActiveFilter = Object.keys(catalogState.filter).length !== 0;
  return (
    <Grid2>
      {isActiveFilter && (
        <>
          <Chip
            size="small"
            label="Очистить"
            onDelete={clearFilter}
            color="primary"
            variant="outlined"
            sx={{ m: 1 }}
          />
          {Object.keys(catalogState.filter).map((item) => {
            const filterOptions = filtersOptions[item];
            return catalogState.filter[item].map((value) => {
              const itemList = filterOptions.list.find(
                (item) => item.value === value,
              );
              return (
                <Chip
                  key={value + item}
                  size="small"
                  label={itemList.label}
                  onDelete={handleChange(
                    itemList?.relation
                      ? [itemList.value, ...itemList?.relation].join(',')
                      : itemList.value,
                    item,
                    true,
                  )}
                  avatar={<Avatar>{filterOptions.avatar}</Avatar>}
                  color="primary"
                  variant="outlined"
                  sx={{ m: 1 }}
                />
              );
            });
          })}
        </>
      )}
    </Grid2>
  );
};

export default React.memo(ActiveFilters);
