import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCategoriesTree } from '../store/productCategories';
import { getTaresSelectFilter } from '../store/tares';
import { getStylesSelectFilter } from '../store/styles';
import { getSizesSelectFilter } from '../store/sizes';
import getCurrentCategory from '../utils/getCurrentCategory';
import productCategoriesConvertForFilter from '../utils/productCategoriesConvertForFilter';
import { getManufacturersByCategory } from '../store/manufacturers';

const FilterList = ({ children }) => {
  const { category } = getCurrentCategory();
  const categories = useSelector(getCategoriesTree(category.id));

  const variationsList = {
    categories: productCategoriesConvertForFilter(categories),
    tare: useSelector(getTaresSelectFilter()),
    size: useSelector(getSizesSelectFilter()),
    style: useSelector(getStylesSelectFilter()),
    manufacturer: useSelector(getManufacturersByCategory()),
  };

  const clonedElements = React.Children.map(children, (child) => {
    const { name } = child.props;
    const config = {
      ...child.props,
      name,
      variations: variationsList[name] ?? [],
    };
    return React.cloneElement(child, config);
  });
  return <>{clonedElements}</>;
};
FilterList.defaultProps = {};
FilterList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default React.memo(FilterList);
