const CATALOG_KEY = 'catalogState';
const EXPIRES_KEY = 'expires';

export function setStorageCatalogState(catalog) {
  // const expiresDate = new Date().getTime() + expiresIn * 1000;
  localStorage.setItem(CATALOG_KEY, JSON.stringify(catalog));

  // localStorage.setItem(EXPIRES_KEY, expiresDate.toString());
}

export function getStorageCatalogState() {
  return JSON.parse(localStorage.getItem(CATALOG_KEY));
}

export function removeCatalogState() {
  localStorage.removeItem(CATALOG_KEY);
}

const localStorageService = {
  setStorageCatalogState,
  getStorageCatalogState,
};

export default localStorageService;
